@font-face {
  font-family: 'proximas-thin', sans-serif;
  font-weight: 300;
  src: local('proximas-thin '), url(./fonts/Proxima-nova-thin.woff) format('truetype');
}
@font-face {
  font-family: 'proximas-regular', sans-serif;
  font-weight: 500;
  src: local('proximas-regular'), url(./fonts/Proxima-nova-regular.woff) format('truetype');
}
@font-face {
  font-family: 'proximas-semi-bold', sans-serif;
  font-weight: 700;
  src: local('proximas-semi-bold '), url(./fonts/proximanova-semibold-webfont.woff) format('truetype');
}
@font-face {
  font-family: 'proximas-bold', sans-serif;
  font-weight: bold;
  src: local('proximas-bold '), url(./fonts/proximanova-bold-webfont.woff) format('truetype');
}
@font-face {
  font-family: 'montserrat-light', sans-serif;
  font-weight: 300;
  src: local('montserrat-light '), url(./fonts/Montserrat-Light.otf) format('truetype');
}
@font-face {
  font-family: 'montserrat-regular', sans-serif;
  font-weight: 500;
  src: local('montserrat-regular '), url(./fonts/Montserrat-Regular.otf) format('truetype');
}

html{
  font-family: 'Montserrat', sans-serif;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  position: relative;
  margin: 0;
  font-family: 'proximas-regular', sans-serif !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
  line-height: 1.42857143 !important;
  /* overflow: hidden !important; */
}
h1, h2, h3, h4, h5 , h6{
  margin: 0;
  color: inherit;
}
*:focus{
  box-shadow: none;
  outline: 0;
}
@media (max-width: 1024px) {
  input[type='text'],
  input[type='number'],
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}
.ant-tabs{
  line-height: 1.42857143 ;

}
.backdrop{
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0,0,0,0.3);
  width: 100vw;
  height: 100vh;
  z-index: 4;

}
.modal{
  position: absolute;
  top: 26px;
  color: black;
  background-color: white;
  width: 280px;
  padding: 20px;
  border-radius: 4px;
  z-index: 5;


  &.fade-in{
    animation-name: fadeInAnimation;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }
  
  &.fade-out{
    animation-name: fadeOutAnimation;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }

  @keyframes fadeInAnimation {
    0%{top: -300px; opacity: 0;}
    100%{top: 26px; opacity: 1;}
  }
  @keyframes fadeOutAnimation {
    0%{top: 26px; opacity: 1;}
    100%{top: -300px; opacity: 0;}
  }



  .ant-select-multiple .ant-select-selection-item{
    margin-right: 13px;
    margin-bottom: 10px;
    border-radius: 87.8px !important;
    border: .9px solid #d2d2d2!important;
    display: flex;
    align-items: center;
    background-color: #f5f5f5!important;
    cursor: pointer;
    
   span:nth-child(1){
      line-height: 13px;
      font-size: 10px;
      color: rgba(0,0,0,.53);
    }
    span:nth-child(2){
      width: fit-content;
      // cursor: pointer;
    }
    .ant-select-selection-item-remove{
      span{
        color: #000;
        opacity: .2;
      }

      &:hover{
        span{
          color: #000;
          opacity: .5;
        }
      }
    }
  }


}
.App {
  height: 100vh;
}


.table-pages-header{
  // position: fixed;
  z-index: 8;
  right: 0;
  left: 0;
  width: auto;
  top: 60px;
  margin-bottom: 11px;
  background: #fff;
  height: 57px;
  display: flex;

  &.mobile{
    display: none;
  }

  @media (max-width: 768px) {
    &.large{
      display: none;
    }
    &.mobile{
      display: flex;
    }
  }

  .search-bar{
    width: 24%;
    display: flex;
    align-items: center;

    .fa-search{
      font-size: 20px;
      padding-left: 40px;
    }

    input{
      padding-left: 30px;
      font-size: 20px;
      padding-top: 8px;
      background: 0 0;
      border: none;
      outline: 0;
      position: relative;
      margin-left: 0;
      font-weight: 400;
      font-style: normal;
      color: rgba(67, 67, 67, 0.51);
      letter-spacing: 1.5px;
    }
  }

  .filter{
    width: 60%;
    background: #fff;
    padding: 14px 22px 14px 13px;
    box-shadow: 0 0 10px rgba(51, 51, 51 / 22%, .4);
    display: flex;

    @media (max-width: 969px) {
      display: none;
    }

    .dropdown-filter{ 

      .options-select-btn:hover{
        background-color: transparent;
      }
    
      .options-select-btn .option-selected, .options-select-btn .caret-icon{
        border: 1px solid #d6d6d6;
      }

      .modal{
        border: 1px solid rgba(0,0,0,.15);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgb(0, 0, 0 / 18%);
        box-shadow: 0 6px 12px rgba(0, 0, 0 / 18%, .1);
        margin-top: 0;
      }
    }
  }
  .delete-btn{
    width: 18%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 22px;
    margin-left: auto;
  }
}

.ant-pagination-options{
  display: none;
}
// .upload-image {
//   .anticon-eye {
//     display: none;
//   }

//   .ant-upload-picture-card-wrapper {
//     margin-left: 25px;
//   }

//   .ant-upload-list-picture-card .ant-upload-list-item {
//     padding: 0px;
//     border: none;
//   }

//   .ant-upload-list-picture-card-container, .ant-upload.ant-upload-select-picture-card {
//     width: 50px;
//     height: 50px;
//     border: 2px dashed #b9b9b9;
//     border-radius: 6px;

//     &:hover {
//       border-color: #b9b9b9;
//     }

//     .material-icons {
//       font-size: 20px;
//     }
//   }
// }

.mr-10{
  margin-right: 10px;
}
.ml-10{
  margin-left: 10px;
}
.fw-100 {
  font-weight: 100;
}

.fw-500 {
  font-weight: 500;
}

.fw-300 {
  font-weight: 300;
}

.fw-700 {
  font-weight: 700;
}

.fw-bold {
  font-weight: bold !important;
}


.fs-6 {
  font-size: 6px;
}

.fs-8 {
  font-size: 8px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-32 {
  font-size: 32px;
}

.fs-34 {
  font-size: 34px;
}

.fs-36 {
  font-size: 36px;
}

.fs-38 {
  font-size: 38px;
}

.fs-40 {
  font-size: 40px;
}

.fs-45 {
  font-size: 45px;
}

.fs-50 {
  font-size: 50px;
}

.fs-55 {
  font-size: 55px;
}

.fs-60 {
  font-size: 60px;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;
}


.table{

   thead tr {
      background: #fff;

      th{
        background-color: #fff;  
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        color: rgba(37,45,71,.62);
        border-bottom: 1px solid rgba(0,0,0,.05);
        padding-right: 10px;
        padding-left: 10px;
      }
    }
    tbody{

      tr{
        
        &:hover{
          td{
            background-color: #fff;
            box-shadow: 0 4px 2px -3px rgba(0, 0, 0 / 10%, .1) inset, 0 -4px 2px -3px rgba(0, 0, 0 / 10%, .1) inset;
            opacity: 1 !important;
          }
        }
        &:nth-child(even) {
          background: #fbfbfb;
        }

        td{
          height: 50px;
          padding: 5px 10px 5px 10px;
          color: #000;
          font-family: ProximaNova-Regular,Sans-Serif;
          font-size: 13px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: .6px;
          color: rgba(0,0,0,.59);
          border: 0;
          vertical-align: middle;
          border-bottom: 1px solid rgba(132,132,132,.08);
        }
      }    
    }
    
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background: none;
      border-color: rgba(0, 0, 0, 0.03);
    }    
}

.details-list{
  padding: 10px 22px;

  ul{
    list-style: none;
    font-size: 14px;
    font-family: 'proximaNova-regular',Sans-Serif;

    li{
      border-bottom: 1px solid #f1f1f1;
      height: auto;
      line-height: 1.14;
      width: 100%;
      padding: 7px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      label{
        width: auto;
        color: rgba(0,0,0,.6);
        font-weight: 700;
      }
      .label-details{
        color: rgba(0,0,0,.8);
        width: 58.33333333%;
      }
    }
  }
}

.more{
  position: relative;
  padding-left: 10px;
  cursor: pointer;

  &:hover .dropdown{
    display: block;
  }

  .more-icon{
    color: rgba(0,0,0,.5);
    font-size: 30px;
    
    &:hover{
      color: #333;
    }
  }

  .dropdown{
    display: none;
    position: absolute;
    top: 34px;
    left: -80px;
    list-style: none;
    text-align: center;
    min-width: 135px;
    margin-top: 2px;
    padding: 5px 0;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0 / 18%, .1);
    box-shadow: 0 6px 12px rgba(0, 0, 0 / 18%, .1);
    z-index: 1;

    &::before{
      position: absolute;
      display: inline-block;
      content: "";
      top: -9px;
      right: 13px;
      border-bottom: 8px solid #e8e8e8;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;   
    }
    
    li{
      display: block;
      width: 100%;
      text-align: left;
      padding: 10px 20px;
      height: 35px;
      border-left: 3px solid transparent;
      line-height: 1;
      color: rgba(0,0,0,.73);
      cursor: pointer;
      font-weight: 400;

      &:hover{
        color: #4695f6;
        border-left: 3px solid #4695f6;
        border-radius: 0;
      }
    }
  }
}

.ant-table-body{
  overflow: auto !important;
  height: calc(100vh - 300px) !important;
}

.half-drawer{
  font-family: 'montserrat-light';

  .ant-drawer-content-wrapper{
    height: calc(100% - 60px) !important;
    bottom: 0;
  }
  .ant-drawer-mask{
    background: rgba(0,0,0,.85) !important;
  }
  .ant-drawer-body{
    padding: 0;
  }
}