.customer-form{
  font-family: 'proximas-regular';
  padding-bottom: 15px;

  .form-header{
    padding: 15px;

    h3{
      letter-spacing: 1.5px;
      // margin-bottom: 20px;
      color: #666666;
      font-size: 30px;
    }
  }
  .form-row{
    padding: 10px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .form-col{
      position: relative;
      padding: 15px;
      padding-bottom: 0;
      width: 50%;


      label{
        text-transform: uppercase;
        display: block;
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: .6px;
        color: rgba(0,0,0,.5);
        margin-bottom: 0;
        padding: 0 10px 0 0;
  
        &.required::after{
          content: '*';
          font-size: 10px;
          margin-left: 2px;
          color: #FF0000;
        }

        &.tags-label{
          text-transform: capitalize;
          font-size: 14px;
          color: rgba(0,0,0,0.87);
          margin-bottom: 5px;

        }
      }
      input{
        width: 100%;
        background: 0 0;
        border: none;
        outline: 0;
        border-bottom: 1px solid #c8c8c8;
        width: 100%;
        padding: 3px 10px 10px;
        border-radius: 0;
        color: rgba(0,0,0,.71);
        letter-spacing: .9px;
        position: relative;

        &[type=tel]{
          padding-left: 60px;
        }

        &:focus{
          border-bottom-color: #4695f6;
        }
        &[type=checkbox]{
          width: 20px;
          opacity: 0;
          cursor: pointer;
          z-index: 2;
        }
        &:checked + .checkmark{
          background-color: #7ed321;

          &::after{
            display: block;
            left: 4px;
            top: 1px;
            width: 8px;
            height: 13px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .logi-lati{
        position: absolute;
        top: 33px;
        right: 20px;
        color: #4695f6;
        font-size: 14px;
        cursor: pointer;
      }
      select{
        position: absolute;
        top: 30px;
        left: 5px;
        width: fit-content;
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding-left: 7px;
        color: rgba(0,0,0,.71);

        background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='rgba(0,0,0,.6)' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
        background-position: calc(100% - 0.75rem) center;
        padding-right: 30px;
  
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
      }

      .form-user{
        position: relative;
        top: -2px;
        left: 10px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.8);
      }
      .checkmark {
        position: absolute;
        // top: 0;
        cursor: pointer;
        left: 15px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid rgba(0,0,0,.2);
        transition: all .3s ease;

        &::after{
          content: "";
          position: absolute;
          display: none;
        }
      }
    }
  }
  .map{
    // margin-top: 10px;
    padding: 15px;
    width: 100%;
    height: 240px;

    @media (max-width: 768px) {
      .dashboard-map{
        height: 100%;
      }
    }
  }
  .form-footer{
    max-width: none;
    width: auto;
    height: 60px;
    text-align: right;
    padding: 17px;
    border-top: 1px solid #d8d8d8;
  }
}