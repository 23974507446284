.customer-details{

  .sub-heading{
    font-size: 16px;
    letter-spacing: .2px;
    color: rgba(51,51,51,.8);
    margin: 0 0 5px;
    font-family: 'proximas-semi-bold', sans-serif;
    font-weight: 700;
  }
}