.dropdown-filter{
  position: relative;

  .options-select-btn{
    display: flex;
    cursor: pointer;

    &:hover{
      background-color: rgba(158,158,158,.2); 
    }
    .option-selected{
      cursor: pointer;
      border: 1px solid rgba(230,230,230,.61);
      height: 28px;
      padding: 0px 10px;
      padding-top: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
    }
    .caret-icon{
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(230,230,230,.61);
      border-left: none;
      cursor: pointer;
      .fa-caret-down{
        transition: transform 0.3s ease;
      }
      .caret-rotate{
        transform: rotate(180deg);
      } 
    }

  }
  .modal{
    position: absolute;
    width: max-content;
    padding: 10px 0px;
    overflow-y: auto;
    max-height: 300px;

    .options-select{
      list-style: none;
      font-size: 14px;

      li{
        padding: 5px 20px;
        cursor: pointer;

        &:hover{
          background-color: #f5f5f5;
        }
      }
    }

  }

}