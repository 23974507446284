.charity{
  background-color: #FAFAFA;


  .charity-btn{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .charity-table{
    padding: 20px 15px;
    margin: 0 auto;
    .images{
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
  .modal-wrapper{
    overflow-y: scroll;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    @media (max-width:768px) {
      padding-bottom: 100px;
    }
  
    .modal{
      top: 50px;
      max-width: 460px;
      width: 100%;
      padding: 20px;
      position: relative;
      margin: 0 auto;
  
      @media (max-width: 767px) {
        width: 90%;
      }
    }
  }
}