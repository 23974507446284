.loading{

  .backdrop{
    background: rgba(0,0,0,0);
    z-index: 5;
  }

  .modal{
    top: 47%;
    width: 80px;
    height: 80px;
    padding: 15px;
  }

  .loader{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: relative;
    border-top: 5px solid rgba(0,0,0,.03);
    border-right: 5px solid rgba(0,0,0,.03);
    border-bottom: 5px solid #0096C2;
    border-left: 5px solid #0096C2;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load 1.1s infinite linear;
  }
  @keyframes load {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
      
  }
}