.login{
  background-image: url(../../images/signup-bg.svg);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 100%;
  padding: 25px 35px 0 35px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden !important;
  text-align: center;

  @media (max-width: 767px){
    padding: 25px 20px 0 20px;
  }

  a{
    color: #0096C2;
    text-decoration: none;
    font-size: 15px;
  }

  &-header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 767px){
      flex-direction: column;
    }

    .brand-logo{
      padding-left: 15px;
      img{
        max-width: 100%;
        max-height: 45px;
      }
      @media (max-width: 767px){
       margin: 0 auto;
      }
    }
    .powered-by{
      width: fit-content;
      margin-top: 10px;
      padding-right: 15px;

      span{
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #333333;
      }
      img{
        height: 19px;
        margin-left: 6px;
        vertical-align: middle;
      }
      @media (max-width: 767px){
        margin-top: 20px;
        margin: 0 auto;
      }
    }
  }
  .form{
    max-width: 440px;
    width: 100%;
    margin: 50px auto 0 auto;
    z-index: 1;

    .form-heading{
      margin: 20px 0px 30px 0px;
      text-align: center;
      font-size: 26px;
      color: #505050;
    }
    .email-input, .password-input{
      position: relative;
      margin-bottom: 15px;
    }
    .email-input .img-icon, .password-input .img-icon{
      position: absolute;
      top: 14.5px;
      left: 12px;
    }
    input{
      width: 100%;
      height: 45px;
      padding-left: 40px;
      padding-right: 30px;
      font-size: 15px;
      color: #272727;
      letter-spacing: 1.2px;      
      border-radius: 5px;
      border: 1px solid #BBBBBB;

      @media (max-width: 1024px) {
        font-size: 16px;
      }
      
      &:focus {
        border: 1px solid #2296ffb0;
      }
      &:focus + .fa{
        color: #2296ffb0;
      }
    }
    .fa-eye{
      position: absolute;
      right: 10px;
      top: 15px;
      color: #B8B8B8;
      cursor: pointer;

      @media (max-width: 768px) {
        right: 0px;
        top: 0;
        height: 100%;
        width: 10%;
        justify-content: center;
        display: flex;
        align-items: center;
      
      }
    }

    .fogot-password-link{
      display: block;
      text-align: right;
    }
    .submit-btn{
      width: 100%;
      background-color: #0096C2;
      height: 50px;
      margin-bottom: 18px;
      margin-top: 15px;
      padding: 0 30px;
      border-radius: 4px;
      border: 2px solid #0096C2;
      font-size: 20px;
      font-weight: 500;
      color: white;
      cursor: pointer;
      z-index: 1;
    }
    .custom-register-span{
      color: #949098 !important;
      font-family: 'montserrat-regular';
      font-weight: 100 !important;
      font-size: 15px;
      text-align: left;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }

  }

  .sign-up-bg-wrapper{
    width: 100%;
    

    @media (max-width: 767px){
      // width: 1401px;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      width: auto;
    }

    .sign-up-bg{
      margin-top: auto;
      width: 100%;
    }
    .sign-up-bg-mobile{
      display: none;
  
      @media (max-width: 767px){
        display: block;
      }
    }
  }

  @media (hover){
    .submit-btn:hover{
      background-color: white;
      color: #0096C2;
    }
    a{
  
      &:hover{
        text-decoration: underline;
      }
    }
  } 
}

