.tasks-list{

  .table-pages-header{

    
    .filter{
      width: 100%;
      
      .search-btn{
        background-color: rgb(19, 148, 255);
        border-color: rgb(19, 148, 255) ;
        border: 1px solid #d6d6d6;
        overflow: visible;
        border-radius: 3px;
        min-width: fit-content;
        margin: 0;
        line-height: 28px;
        min-height: 28px;
        text-transform: capitalize;
        color: white;
        box-shadow: none;
        padding: 0 15px;
        margin-right: 10px;
        cursor: pointer;

        &:hover{
          background-color: transparent;
          border-color: rgb(19, 148, 255);
          color: rgb(19, 148, 255);

        }
      }
      .options{
        margin-left: auto;
        display: flex;
        align-items: center;

        .merge-connected-tasks{
          display: flex;
          align-items: center;
          span{
            font-weight: bold;
          }
          .ant-switch{
            margin-left: 10px !important;
          }
        }
        .icon{
          width: 15px;
          height: 15px;
          font-size: 20px;
        }

      }
    }
  }

  .task-table{
    width: 95%;
    margin: 20px auto;
    margin-bottom: 0;
    
    .taskid-id{
      outline: 0;
      border: none;
      color: #2296FF;
      background-color: transparent;
      cursor: pointer;

      &:hover{
        text-decoration: underline;
      }
    }
  }
}