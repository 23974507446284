.side-nav{

  .ant-drawer-content-wrapper{
    width: 230px !important;

    @media (max-width: 995px) {
      width: 100vw !important;
    }
  }

  .ant-drawer-body{
    padding: 0px;
    overflow: hidden;
  }
  .side-nav-header{
    display: flex;
    margin-bottom: 30px;
    padding: 10px 20px;

    .menu-button{
      align-self: center;
      margin-right: 15px;
      cursor: pointer;
  
      div{
        border: 1px solid white;
        border-radius: 5px;
          @media (max-width: 995px) {
            display: none;
          }
        &:nth-child(1){
          width: 15px;
        }
        &:nth-child(2){
          width: 20px;     
          margin: 4px 0;
        }
        &:nth-child(3){
          width: 15px;
        }
      }
        
      .close-button{
        color: white;
        display: none;
        @media (max-width: 995px) {
            display: block;
        }
      }
    }
    .logo{
      align-self: center;
      height: fit-content;
    }
  }


  .side-nav-links{
    
    a{
      font-style: normal;
      color: #939393;
      font-size: 15px;
      letter-spacing: 1.8px;
      font-weight: 400;
      font-stretch: normal;
    
      &:hover{
    
        .nav-link{
          color: white;
          background-color: #272727;
        }
      }
    
      .nav-link{
        transition: all .5s;
        height: 60px;
        padding: 17px 20px;
        white-space: nowrap;
        border-bottom: 1px solid rgba(255,255,255,.05);
      }
    
      &.is-active{
        
        .nav-link{
          background-color: #1984ff;
          color: white;
        }
      }
    
      svg{
        margin-right: 40px;
      }
    }
  }
}




// ant-drawer-body